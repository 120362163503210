var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _c("div", { staticClass: "page-inner" }, [
        _c("div", { staticClass: "page-title-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-title-inner" }, [
              _c("div", { staticClass: "page-title" }, [_vm._v("Finance")]),
              _c(
                "div",
                {
                  staticClass: "button button--fill",
                  on: { click: _vm.generate }
                },
                [_vm._v("Generate Invoices")]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "page-main" }, [
          _c(
            "div",
            { staticClass: "container" },
            _vm._l(_vm.invoices, function(invoice) {
              return _c("Invoice", {
                key: invoice.id,
                attrs: { invoice: invoice }
              })
            }),
            1
          )
        ])
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="invoice__wrapper">
    <div class="invoice__main">
      <div class="invoice__date-wrapper">
        <div class="invoice__date">
          <i class="ri-calendar-fill"></i>
          {{ moment(invoice.dateFrom).format('MMM DD') }} – {{ moment(invoice.dateTill).format('MMM DD') }}
        </div>
      </div>
      <div class="invoice__name">
        {{ invoice.billingName }}
      </div>
      <button class="icon-button icon-button--round">
        <a :href="invoice.invoicePdfURL" target="_blank"><i class="ri-arrow-down-circle-fill"></i></a>
      </button>
      <button
        class="button button--fill button--rounded"
        :class="{'button--green': invoice.status === 'paid'}"
        :disabled="invoice.status === 'paid'"
        @click="mark"
      >
        Done
      </button>
      <button class="icon-button icon-button--round">
        <router-link :to="'/admin/invoices/' + invoice.id">
          <i class="ri-arrow-right-s-line"></i>
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions} from 'vuex'

export default {
  name: 'EnterpriseInvoice',
  props: {
    invoice: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment
    }
  },
  methods: {
    ...mapActions({
      markPayed: 'invoices/confirm'
    }),
    async mark() {
      console.log(this.invoice)
      // const resp = await this.markPayed(this.invoice.id)
      // if (resp.data.success) {
      //   this.invoice.status = 'paid'
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
